// Import Swiper React components
import React from 'react';

export default function Instagram() {
  return (
    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
		viewBox="0 0 91.57 91.57" className="instagram svg-stroke" xmlSpace="preserve">
      <g>
        <rect x="16.45" y="19.08" width="57.19" height="57.02"/>
        <circle cx="62.84" cy="29.95" r="4.1"/>
        <circle cx="45.05" cy="47.99" r="14.09"/>
      </g>
    </svg>
  );
}

