// Import Swiper React components
import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper core and required modules
import SwiperCore, { Navigation } from 'swiper';

// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';

import Instagram from './assets/Instagram';
import Grid from './assets/Grid';
import Cookies from 'js-cookie';

import funky from './assets/products/gorilla-glue-funky.png';
import sour from './assets/products/citrus-slurricane-sour.png';
import spicy from './assets/products/flo-spicy.png';
import sweet from './assets/products/hippy-crasher-sweet.png';
import minty from './assets/products/orange-kush-mints.png';
import pink from './assets/products/sunset-sherbert-pink.png';

// Import Swiper styles
import 'swiper/swiper.scss';

import './App.scss';

// install Swiper modules
SwiperCore.use([Navigation]);

const products = {
  funky,
  sour,
  spicy,
  sweet,
  minty,
  pink,
};

const getProductCopy = (product) => {
  switch (product) {
    case 'minty':
      return 'funky';

    case 'pink':
      return 'sweet';

    default:
      return product;
  }
}

const FullPage = ({product}) => {
  return (
    <section className="slider-fullpage">
      <div className="slider-fullpage--product">
        <img alt={`Something pretty ${getProductCopy(product)} now available at New Standard Cannabis`} src={products[product]} />
      </div>
    </section>
  );
};

function App() {
  const [color, setColor] = useState(Cookies.get('color') || 'sour');
  const [loaded, setLoaded] = useState(false);

  const colors = [
    'sour',
    'funky',
    'spicy',
    'sweet',
    'minty',
    'pink',
  ];


  const handleSlideChange = (event) => {
    const {realIndex} = event;
    setColor(colors[realIndex]);
    document.body.classList = colors[realIndex];
    Cookies.set('color', colors[realIndex]);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 200);

    function handleResize() {
      const root = document.documentElement;
      const { clientWidth, clientHeight } = root;
      const size = clientWidth > clientHeight ? clientWidth / 19 : clientWidth / 8;
      root.style.setProperty('--square-size', `${Math.ceil(size)}px`);
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App">
      <Grid />
      <h1 className={`super-header ${loaded ? 'super-header--active' : ''} bg-filler text-uppercase`}>
        <span className="super-header--text">Super</span>
        <span className="super-header--text super-header--inverted">Super</span>
      </h1>

      <div className="super-text bg-filler text-uppercase">
        <h2>Now available at<br/> New Standard Cannabis</h2>
      </div>

      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        loop
        navigation
        onSlideChange={handleSlideChange}
        onSwiper={(swiper) => {
          let index = colors.lastIndexOf(color);
          index = parseInt(index) + 1;
          if (index > colors.length - 1) {
            index = 0;
          }

          swiper.slideToLoop(index, 0);
        }}
      >
        {colors.map((color) => <SwiperSlide key={color}><FullPage product={color} /></SwiperSlide>)}
      </Swiper>

      <div className="super-info">
        <p className="bg-filler text-uppercase"><a href="https://www.anewstandard.com">Order now</a></p>
        <p className="bg-filler text-uppercase"><a href="mailto:info@anewstandard.com">Contact us</a></p>
        <a className="bg-filler" target="_blank" rel="nofollow noreferrer" href="https://www.instagram.com/supersupercannabis/"><Instagram /></a>
      </div>
    </div>
  );
}

export default App;
